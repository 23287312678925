import React, {useEffect} from 'react';
import {isBrowser} from 'react-device-detect';
import FlipMove from 'react-flip-move';
import Centrifuge from 'centrifuge'
import moment from 'moment-with-locales-es6';

import './App.scss'


interface IListItem {
  last_seen: string
  name: string
  profile_id: string
  profile_image: string
  screentime: number
}

interface IListElementProps {
  item: IListItem
  maxScreenTime: number
}

const ListElement = ({item, maxScreenTime}: IListElementProps) => {
  const style = {
    width: `${Math.ceil(100 * item.screentime / maxScreenTime)}%`
  }
  return <div className='list-item-container'>
    <img src={'https://d3pb42b3k42s7p.cloudfront.net/' + item.profile_image}/>
    <div>
      <h3>{item.name}</h3>
      {/*<span>Id: {item.profile_id}</span>*/}
      <span>Ruutuaika: {item.screentime}s</span>
      <span>{moment(item.last_seen).locale('fi').subtract(5, 'seconds').fromNow()}</span>
      <div className='time-bar'>
        <div style={style}/>
      </div>
    </div>
  </div>
}

interface IFlipListProps {
  data: IListItem[]
  maxScreenTime: number
}

const FlipList = ({data, maxScreenTime}: IFlipListProps) => {
  return <FlipMove>
    {
      data.map((item: IListItem) =>
        <div key={item.profile_id}>
          <ListElement
            item={item}
            maxScreenTime={maxScreenTime}
          />
        </div>)
    }
  </FlipMove>
}


const App: React.FC = () => {
  const [maxScreenTime, setMaxScreenTime] = React.useState<number>(1)
  const [data, setData] = React.useState<IListItem[]>([])
  const [lastSeenData, setLastSeenData] = React.useState<IListItem[]>([])
  const [rankData, setRankData] = React.useState<IListItem[]>([])
  const [resizeTimeout, setResizeTimeout] = React.useState<number|undefined>(undefined)

  const updateData = (newItem: IListItem) => {
    // let newData: IListItem[] = []

    setData((previousData: IListItem[]) => {
      let newData: IListItem[] = []

      const existingFace = previousData.some((item: IListItem) => newItem.profile_id === item.profile_id)

      if (existingFace) {
        newData = previousData.map(
          (item: IListItem) =>
            newItem.profile_id === item.profile_id ?
              {profile_image: item.profile_image, ...newItem} :
              {...item}
        )
      } else {
        newData = [...previousData].concat([newItem])
      }

      return newData
    })
  }

  useEffect(() => {
    const conn = new Centrifuge('wss://linnanjuhlat.apps.emblica.com/connection/websocket', {
      debug: true,
    });
    conn.setToken('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjMiLCJuYW1lIjoiUHVibGljIiwiaWF0IjoxNTE2MjM5MDIyfQ.5OHfNn_xjXZRgJq9XrOLIuD2oM_XVk9Q-z5yGMeKcj4');
    const subsc = conn.subscribe("raw_seen", function (message) {
      const newItem: IListItem = message.data
      updateData(newItem)
    });
    subsc.on('error', (er) => {
      console.log('centrifuge stream error', er)
    })
    subsc.on('join', (dt) => {
      console.log('centrifuge joined to stream', dt)
    })
    subsc.on('leave', (dt) => {
      console.log('centrifuge stream closed', dt);
    })
    conn.connect();
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setData((currentData) => {
        console.log('data len', currentData.length)

        const newLastSeen: IListItem[] = [...currentData]
        newLastSeen.sort((a: IListItem, b: IListItem) => {
          const msDiff = moment(b.last_seen).diff(moment(a.last_seen))
          if (Math.abs(msDiff) < 5000) {
            return 0
          }
          return b.last_seen > a.last_seen ? 1 : -1
        })
        setLastSeenData(newLastSeen)

        const newRanked: IListItem[] = [...currentData]
        newRanked.sort((a: IListItem, b: IListItem) => b.screentime - a.screentime)
        setRankData(newRanked)

        return currentData
      })
    }, 2000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (!isBrowser) {
      return
    }

    const onSizeUpdate = () => {
      setResizeTimeout((oldResizeTimeout: number|undefined) => {
        if (oldResizeTimeout !== undefined) {
          window.clearTimeout(oldResizeTimeout)
        }

        const newTimeout: number = window.setTimeout(() => {
          window.location.reload()
        }, 1000)

        return newTimeout
      })
    }
    window.addEventListener('resize', onSizeUpdate)

    return () => window.removeEventListener('resize', onSizeUpdate)
  }, [])

  moment.locale('fi')

  return (
    <div className='app'>
      <div className='wrapper'>
        <div className='page-content'>
          <div className='video-container'>
            <h1>AI katsoo linnanjuhlia</h1>
            {/*<div className="yle_areena_player" data-id="1-50272414"/>*/}
            <div className="yle_areena_player" data-id="yle-tv1"></div>
            {/*<script src="https://player.yle.fi/assets/js/embed.js" defer></script>*/}
            <p>
              Tänä vuonna linnanjuhlien teemana on tieto ja yhteiskunnallinen keskustelu. Tästä inspiroituneena päätimmä antaa myös tekoälylle mahdollisuuden tutustua perinteiseen juhlaan. Tekoäly pyrkii tunnistamaan kasvojen perusteella Ylen lähetyksestä henkilöt ja sen perusteella laskee mm. ruutuajan. Nykyinen toteutus on mahdollisimman yksinkertainen (ja nopea) joten tällä on lähinnä huumoriarvoa, Emblican porukka toivottaa silti jokaiselle hyvää itsenäisyyspäivää!
            </p>
            <p>
              Mahdollisia ongelmia:
            </p>
            <ul>
              <li>Sauli Niinistö tunnistautuu kolmena eri henkilönä</li>
              <li>Tekoäly ei tunnista edes Jenni Haukiota</li>
              <li>Vanhat henkilöt menevät sekaisin keskenään</li>
              <li>Silmälasit sekoittavat</li>
              <li>Tekoäly jää jälkeen liven seurannasta</li>
              <li>Tekoäly kaatuu</li>
              <li>Tekoäly katsoo liian nopeasti liveä</li>
              <li>Tekoäly näkee koristekukkasissa kasvoja</li>
            </ul>
          </div>
          <div className='list-container'>
            <div className='list'>
              <h3>Eniten ruutuaikaa</h3>
              <FlipList data={rankData.slice(0, 20)} maxScreenTime={maxScreenTime}/>
            </div>
            <div className='list'>
              <h3>Viimeksi nähdyt</h3>
              <FlipList data={lastSeenData.slice(0, 20)} maxScreenTime={maxScreenTime}/>
            </div>
          </div>
        </div>
        <div className='page-footer'>
          <span>Powered by <a href='https://emblica.fi/'>emblica</a></span>
        </div>
      </div>
    </div>
  );
}

export default App;
